<template>
  <div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="ChangeSelectionModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      ref="ChangeSelectionModal"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content p-4">
          <div class="container p-2 pb-4">
            <!-- <div v-if="isAllProductLoading">
            <LoaderComp/>
         </div> -->

            <div>
              <div class="row px-3">
                <div
                  class="d-flex  col-12 justify-content-between align-items-center ShopifyTopSection"
                >
                  <p class="TitleHead mt-3 text-center">
                    Select Products
                    <span
                      class="d-block "
                      v-if="Products.length"
                      style="font-size: 13px"
                    >
                      (Total {{ Products.length }} Products loaded)</span
                    >
                  </p>
                  <!-- <button @click="closeModal" class="btn" style="">
                  
                    <b-icon-x-lg></b-icon-x-lg>
                  </button> -->
                   <div class="d-flex align-items-center">
                    <button
                      class="btn px-3 mr-4 py-2 text-white"
                      @click="closeModal"
                      style="background: #b3afb6; border-radius: 6px"
                    >
                      Cancel
                    </button>
                    <button
                      :disabled="
                        !selectedProducts.length && saveProductsSpinner
                      "
                      class="btn px-3 py-2 text-white"
                      style="background: #4d1b7e; border-radius: 6px"
                      @click="saveProducts"
                    >
                      <span v-if="!saveProductsSpinner">Save Product</span>
                      <div
                        v-else
                        class="spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span class="sr-only">Loading...</span>
                      </div>
                    </button>
                  </div>
                </div>
              </div>

              <div class="row mt-2" style="min-height: 400px">
                <div
                  class="col-12 d-flex justify-content-between align-items-center h-100"
                >
                  <b-form-input
                    class="w-50"
                    placeholder="Search Products"
                    v-model="searchProducts"
                  ></b-form-input>

                  <div class="mr-5 d-flex align-items-center">
                    <div class="mr-3 mb-n3">
                      <b-form-group
                    class="text-dark  "
                  >
                    <b-form-select v-model="selectStatus" class="">
                      <b-form-select-option selected disabled :value="null">
                          Status
                      </b-form-select-option>
                      <b-form-select-option
                        value="all"
                        >All</b-form-select-option
                      >
                      <b-form-select-option
                        value="active"
                        >Active</b-form-select-option
                      >
                      <b-form-select-option
                        value="draft"
                        >Draft</b-form-select-option
                      >
                      <b-form-select-option
                        value="archived"
                        >Archived</b-form-select-option
                      >

                    </b-form-select>
                  </b-form-group>
                     
                    </div>
                    <div class="m-0 selectedPara">
                      <span v-if="!productSelecting">
                        {{ selectedProducts.length }} selected</span
                      >
                      <div
                        v-else
                        class="spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 ShopifyProductDiv">
                  <div class="" v-if="isAllProductLoading">
                    <LoaderComp />
                  </div>
                  <div class="ShopifySelectTable" v-else>
                    <!-- <vue-bootstrap4-table
                ref="ShopifyProductTable"
                class="DataTable"
                :rows="getProductsTable"
                :columns="columns"
                :config="config"
                @on-select-row="OnRowCheckBoxChanged($event)"
                @on-all-select-rows="
                OnRowCheckBoxChanged($event)
                "
                @on-unselect-row="OnRowCheckBoxChanged($event)"
                @on-all-unselect-rows="
                OnRowCheckBoxChanged($event)
                "
                >
                <template slot="Image" slot-scope="props">
                    <LazyImage :src="ImageSrc( props.row.node.images)"   style="height:50px;width:50px;border-radius:50%;cursor:pointer;" alt="" />
                </template>
                <template slot="Title" slot-scope="props" style="text-align:left !important">
                    <p class="ProductTitle" style="cursor:pointer;" >{{props.row.node.title}}</p>
                </template>
                </vue-bootstrap4-table> -->
                <div v-if="getProductsTable.length">
                    <table
                      class="table table-striped text-center table-sm mt-3"
                      
                    >
                      <thead
                        class="py-3 w-100"
                        style="background: #4d1b7e; color: #ffffff"
                      >
                        <tr>
                          <th scope="col">
                            <div>  <b-form-checkbox
      id="SelectAllCheckbox"
      v-model="selectAllProducts"
      name="SelectAll"
       @change="selectAllProductsEvent($event)"
    >
    </b-form-checkbox></div>
                          </th>
                          <th scope="col">Image</th>
                          <th scope="col">Title</th>
                          <th scope="col">Status</th>
                        </tr>
                      </thead>
                        
                      <tr v-for="(item,index) in getProductsTable" :key="`${item.id}-${index}`" >
                        <td style="text-align: center; width: 100px">
                          <div>
                          
                             <b-form-checkbox
    :id="`${item.id}-${Date.now()}`"
      v-model="selectedProducts"
      :name="`${item.id}-${Date.now()}`"
      :value="item.admin_graphql_api_id"
     @change="toggleSingleProduct"
     style="cursor:pointer;"
     
    >
    </b-form-checkbox>
    
     <!-- :disabled="isProductAlreadyPresentInDB(item.admin_graphql_api_id)" -->
    <!-- :style="isProductAlreadyPresentInDB(item.admin_graphql_api_id) ? 'opacity:0.5;':null" -->
                          </div>
                        </td>

                        <td >
                          <LazyImage
                            :src="ImageSrc(item.image?.src)"
                            :key="`${index}-${item.id}`"
                            style="
                              height: 50px;
                              width: 50px;
                              border-radius: 50%;
                              cursor: pointer;
                            "
                            alt=""
                          />
                        </td>
                        <td>
                          <p class="ProductTitle" style="cursor: pointer">
                            {{ item.title }}
                          </p>
                        </td>
                        <td>
                          <b-badge pill v-if="item.status == 'active'" class="px-3 py-2 text-white" style="background:#64BC26;">Active</b-badge>
                                    <b-badge pill v-if="item.status == 'draft'" class="px-3 py-2 text-white" style="background:#B3AFB6;">Draft</b-badge>
                                    <b-badge pill v-if="item.status == 'archived'" class="px-3 py-2 text-white" style="background:#ea9c00;">Archived</b-badge>
                        </td>
                      </tr>
                      
                    </table>
                </div>
                <div v-else>
                  <table
                      class="table table-striped text-center table-sm mt-3"
                      
                    >
                      <thead
                        class="py-3 w-100"
                        style="background: #4d1b7e; color: #ffffff"
                      >
                        <tr>
                          <th scope="col">
                            <div>  <b-form-checkbox
      id="SelectAllCheckbox"
      name="SelectAll"
    >
    </b-form-checkbox></div>
                          </th>
                          <th scope="col">Image</th>
                          <th scope="col">Title</th>
                          <th scope="col">Status</th>
                        </tr>
                      </thead>
                        
                    
                      
                    </table>

                     <div class="" >
                      <div v-if="selectStatus">
                        <p class="TitleHead" style="text-align:center;">No Product found for <span>"{{selectStatus.toUpperCase()}}" status!</span></p>
                        <p class="TitleHead" style="text-align:center;font-size:16px;" >Try changing status or load more products!</p>
                      </div>
                      <div v-else>
                        <p class="TitleHead" style="text-align:center;">No Products found!</p>
                      </div>
                      <div class="mt-2" style="text-align:center;" v-if="pageInfo && pageInfo.next">
                           <button
                      class="NavButton px-2"
                      v-if="pageInfo && pageInfo.next"
                      @click="NextPageCLick"
                      :disabled="isnextClickLoading"
                    >
                      <span v-if="!isnextClickLoading"> Load More</span>
                      <div
                        v-else
                        class="spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span class="sr-only">Loading...</span>
                      </div>
                    </button>
                      </div>
                     </div>
                </div>
                  
                  </div>
                </div>

                <div
                  class="col-12 d-flex justify-content-between align-items-center ShopifyBottomSection pt-3"
                >
                  <div>
                    <!-- <button class="NavButton mr-3" v-if="pageInfo && pageInfo.hasPreviousPage" @click="PrevPageCLick" :disabled="isprevClickLoading">
                     <span v-if="!isprevClickLoading"> <BIconArrowLeft></BIconArrowLeft></span>
                    <div
                        v-else
                        class="spinner-border spinner-border-sm"
                        role="status"
                    >
                        <span class="sr-only">Loading...</span>
                    </div>
                       
                </button> -->
                    <button
                      class="NavButton px-2"
                      v-if="pageInfo && pageInfo.next"
                      @click="NextPageCLick"
                      :disabled="isnextClickLoading"
                    >
                      <span v-if="!isnextClickLoading"> Load More</span>
                      <div
                        v-else
                        class="spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span class="sr-only">Loading...</span>
                      </div>
                    </button>
                  </div>
                  <div class="d-flex align-items-center">
                    
                    <button
                      :disabled=" loadAllProductsSpinner
                      "
                      class="btn px-3 py-2 text-white"
                      style="background: #4d1b7e; border-radius: 6px"
                      @click="loadAllProducts"
                    >
                      <span v-if="!loadAllProductsSpinner">Load All Products</span>
                      <div
                        v-else
                        class="spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span class="sr-only">Loading...</span>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from "lodash";
import axios from "axios";
import LoaderComp from "../../../customize/component/LoaderComp.vue";
// import VueBootstrap4Table from "vue-bootstrap4-table";
import { mapGetters } from "vuex";
// import { BIconArrowRight } from "bootstrap-vue";
import $ from "jquery";
export default {
  components: {
    // VueBootstrap4Table,
    LoaderComp,
  },
  props: ["dbProducts"],
  data() {
    return {
      isAllProductLoading: false,
      searchProducts: "",
      config: {
        checkbox_rows: true,
        rows_selectable: false,
        pagination: false,
        pagination_info: false,
        num_of_visibile_pagination_buttons: 5,
        per_page: 10,
        per_page_options: [10, 20, 30, 50, 100],
        show_refresh_button: false,
        show_reset_button: false,
        card_mode: true,
        card_title: "",
        highlight_row_hover_color: "#f2f2f2",

        global_search: {
          placeholder: "Enter custom Search text",
          visibility: false,
          case_sensitive: false,
          showClearButton: false,
          searchOnPressEnter: false,
          searchDebounceRate: 1000,
        },
      },
      columns: [
        {
          label: "Image",
          name: "image",
          slot_name: "Image",
        },
        {
          label: "Title",
          name: "title",
          slot_name: "Title",
        },
      ],
      Products: [],
      selectedProducts: [],
      pageInfo: {},
      isnextClickLoading: false,
      productSelecting: false,
      saveProductsSpinner: false,
      selected: [],
      selectAllProducts:false,
      loadAllProductsSpinner:false,
      selectStatus:null,

    };
  },
  methods: {
    // PrevPageCLick(){
    //      this.getAllProducts(false,true)
    // },
    NextPageCLick() {
      this.getLoadMoreProducts();
    },
    resetModal() {
      this.searchProducts = "";
      this.selectedProducts = [];
      this.Products = [];
      this.selectStatus=null;
    },
    OpenModal() {
      this.resetModal();
      this.getAllProducts();
    //  this.selectedProducts = this.dbProducts.map(x=> x.shopify_id)  
      $("#ChangeSelectionModal").modal("show");
    },
    closeModal() {
      $("#ChangeSelectionModal").modal("hide");
    },

    ImageSrc(img) {
      if (img) return img;
      return 'https://images.quizell.com/website/default_start_page.png';
    },
    async OnRowCheckBoxChanged(e) {
      try {
        this.productSelecting = true;
        await this.updateSelectedProduct(e.selected_items);
      } catch (error) {
        console.log("Error Occured", error);
      } finally {
        this.productSelecting = false;
      }
    },
    updateSelectedProduct(items) {
      this.selectedProducts = items;
    },
    debounceInput: debounce(function () {
      this.getAllProducts();
    }, 1000),

    async getLoadMoreProducts() {
      // let isNextClicked = false
      try {
        this.isnextClickLoading = true;
        let data = {
          // title: this.searchProducts,
          // direction: "next",
          cursor: this.pageInfo.next,
        };

  
        const response = await axios.get(`/loadShopifyProducts`, {
          params: data,
        });
        if (response.status == 200 ) {
          //   this.Products = this.filterProducts(response.data.data.products);
          this.Products = [
            ...this.Products,
            ...(this.filterProducts(response.data.data.products) ?? []),
          ]

          this.pageInfo = response.data.data.link;
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error occured", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
        throw error;
      } finally {
        this.isnextClickLoading = false;
      }
    },
    async getAllProducts() {
      this.isAllProductLoading = true;
      try {
        // let data = {
        //   title: this.searchProducts,
        // };
        // const response = await axios.get(`/loadShopifyProducts`, {params: data});
        const response = await axios.get(`/loadShopifyProducts`);
        if (response.status == 200 ) {
        //   this.Products = this.filterProducts(response.data.data.products);
          // this.Products = [
          //   ...this.Products,
          //   ...(response.data.data.products ?? []),
          // ]
          this.Products = [
            ...this.Products,
            ...(this.filterProducts(response.data.data.products)  ?? []),
          ]

          // this.pageInfo = response.data.data.pageInfo;
          this.pageInfo = response.data.data.link;
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error occured", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
        throw error;
      } finally {
        this.isAllProductLoading = false;
      }
    },

    async saveProducts() {
      this.saveProductsSpinner = true;
      try {
        let finalArr = await this.filterFinalProducts(this.selectedProducts)
        let data = {
          products: JSON.stringify(finalArr),
          _token: this.GetTokenFromMetaTag,
        };
        const response = await axios.post(`/saveSelectedProducts`, data);
        if (response.status == 200 ) {
          this.closeModal();
          this.resetModal();
          this.$emit("callDBProducts");
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error occured", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
        throw error;
      } finally {
        this.saveProductsSpinner = false;
      }
    },
    filterProducts(arr) {
      let result = arr.filter((obj1) =>
        this.dbProducts.some((obj2) => obj1.admin_graphql_api_id
 == obj2.shopify_id)
      );

      const finalArr = arr.filter((el) => !result.includes(el));
      return finalArr;
    },
   async filterFinalProducts(arr) {
      const InitialArr = await arr.filter((el) => !this.getDBSelectedProducts.includes(el));
     const finallArr =   this.Products.filter((x) => InitialArr.some((y)=>y==x.admin_graphql_api_id));
     return finallArr.length ? finallArr :null
      // return finalArr;
      
    },
    toggleSingleProduct(){
         this.selectAllProducts = false
    },
   async selectAllProductsEvent(e){
        if(e){
            try {
                 this.productSelecting = true;
                 this.selectedProducts = await this.Products.map(x=> x.admin_graphql_api_id) 
            } catch (error) {
                if(error){
                      this.$toasted.show("Error occured", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
                }
               
            }
            finally {
                 this.productSelecting = false
            }
            
           
           
        }
        else {
             this.selectedProducts = []
        }
    },
    isProductAlreadyPresentInDB(id){
      let index = this.getDBSelectedProducts.findIndex(x=> x == id)
      return index > -1 ? true : false
    },
    async loadAllProducts(){
       this.isAllProductLoading = true;
       this.loadAllProductsSpinner = true;
      try {
        let data = {
          title: this.searchProducts,
        };
        const response = await axios.get(`/loadAllShopifyProducts`, {params: data});
       
        if (response.status == 200 ) {
          // this.Products = response.data.data ?? [],
          this.Products = this.filterProducts( response.data.data) ?? [],
          

         
          this.pageInfo = response.data.data.link;
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error occured", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
        throw error;
      } finally {
        this.loadAllProductsSpinner = false;
         this.isAllProductLoading = false;
      }
    }
  },
  computed: {
    ...mapGetters(["GetTokenFromMetaTag"]),
    getProductsTable() {
      // return this.Products;
       return this.Products.filter((item) => {
        return (
          (this.searchProducts.length === 0 ||
            item.title
              .toLowerCase()
              .includes(this.searchProducts.toLowerCase())) 
              &&
          ((this.selectStatus == null || this.selectStatus == 'all' )||
            (item.status !== null &&
              item.status.includes(this.selectStatus)))
        );
      });
    },
    getDBSelectedProducts(){
      return this.dbProducts.map(x=> x.shopify_id)
    }
  
  },
  watch: {
    // searchProducts() {
    //   if (this.searchProducts !== "") {
    //     this.debounceInput();
    //   } else {
    //     this.getAllProducts();
    //   }
    // },
       Products: {
      deep: true,
      handler() {
       this.selectAllProducts = false
      },
    },
  },
};
</script>

<style scoped>
.modal-content {
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(77, 27, 126, 0.1);
  border-radius: 20px;
}
.modal-content .container {
  min-height: 50vh;
}
.ShopifyTopSection {
  border-bottom: 2px solid #dee2e6;
  margin-bottom: 10px;
}
.ShopifyBottomSection {
  border-top: 2px solid #dee2e6;
}

.TitleHead {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 33px;
  color: #161e34;
}

/* Product Data Table Styles  */
.ShopifyProductDiv {
  min-height: 400px;
}
.ShopifySelectTable {
  max-height: 500px;
  overflow: hidden scroll;
  scroll-behavior: smooth;
}

.ShopifySelectTable .DataTable.card {
  border: none;
}
.ShopifySelectTable >>> .card-header {
  display: none !important;
}
.ShopifySelectTable >>> .card-footer {
  background: transparent;
  padding: 0 !important;
  border: none;
}

.ShopifySelectTable >>> .vbt-per-page-dropdown .btn-primary {
  background: rgba(77, 27, 126, 0.8) !important;
  border: none !important;
  color: #ffffff !important;
}
.ShopifySelectTable >>> .vbt-per-page-dropdown .dropdown-item.active {
  background: rgba(77, 27, 126, 0.8) !important;
  text-decoration: none !important;
  color: #ffffff !important;
}

.selectedPara {
  border: 1px solid #ced4da;
  padding: 6px 20px;
  border-radius: 4px;
}

.NavButton {
  border: none;
  outline: none;
  background: #4d1b7e;
  color: #ffffff;
  font-weight: 500;
  min-width: 80px;
  height: 40px;
  border-radius: 6px;
}
</style>
